body {
  font-size: 14px;
  background-color: #f1f3f5;
}

.filters input,
.filters input.form-control {
  max-height: 30px;
  font-size: 13px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.hidden {
  display: none;
}
.visible {
  display: block;
}
.link-outline-success {
  background-color: transparent;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #28a745;
  padding: 1px 5px;
  color: #28a745;
  font-size: 13px;
}
.link-outline-orange {
  background-color: transparent;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #fa7205;
  padding: 1px 5px;
  color: #fa7205;
  font-size: 13px;
}
.mr-1 {
  margin-right: 1px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px;
}
.color-red {
  color: red;
}
.color-green {
  color: green;
}
.sub-nav-bar {
  background: #2cb7df !important;
  margin-top: 10px;
  margin-bottom: 20px;
}
.sub-nav-bar li.nav-item {
  margin-right: 12px;
}
.sub-nav-bar .dropdown-menu {
  margin-top: 7px;
}
.sub-nav-bar a {
  font-size: 15px !important;
}
.sub-nav-bar a {
  color: #ffffff !important;
}
.sub-nav-bar .dropdown-item a {
  color: #000 !important;
}
.sub-nav-bar .dropdown-item a:hover {
  background: #f8f9fa !important;
}

.sub-nav-bar .navbar-nav .nav-link {
  margin: 0px -25px;
  padding: 10px 30px;
}

.tab-container {
  padding: 20px;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.tab-container .table th {
  border-top: none;
}
a.no-link-underline:hover,
.no-link-underline {
  text-decoration: none;
}

.expiry_label_active {
  background-color: green;
  color: #fff;
  padding: 2px 5px;
  width: 70px;
  display: block;
  text-align: center;
}

.expiry_label_expiring {
  background-color: orange;
  color: #fff;
  padding: 2px 5px;
  width: 70px;
  display: block;
  text-align: center;
}

.expiry_label_expired {
  background-color: red;
  color: #fff;
  padding: 2px 5px;
  width: 70px;
  display: block;
  text-align: center;
}

.stock_on_alert {
  background-color: red;
  color: #fff;
  padding: 2px 5px;
  display: block;
  text-align: center;
}

.form-container,
.main-container {
  padding: 15px;
  background: #fff;
}
.plane-border-container {
  padding: 15px;
  border: 1px solid #dee2e6;
  background: #fff;
}
.no-top-border-th th {
  border-top: none;
}
.force-hide {
  display: none !important;
}
.color-red {
  color: red;
}
.bold {
  font-weight: bold;
}

/* success button */
.btn-outline-success {
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  color: #28a745;
}
.btn-outline-success.active:focus:not(:disabled):not(.disabled),
.btn-outline-success:active:focus:not(:disabled):not(.disabled),
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.active:not(:disabled):not(.disabled),
.btn-outline-success:active:not(:disabled):not(.disabled),
.show > .btn-outline-success.dropdown-toggle {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
.btn-outline-success:hover {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff !important;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success:focus {
  color: #28a745;
}
/* ./END - success button */

/* orange button */
.btn-outline-orange {
  background-color: transparent;
  background-image: none;
  border-color: #fa7205;
  color: #fa7205;
}
.btn-outline-orange.active:focus:not(:disabled):not(.disabled),
.btn-outline-orange:active:focus:not(:disabled):not(.disabled),
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-orange.active:not(:disabled):not(.disabled),
.btn-outline-orange:active:not(:disabled):not(.disabled),
.show > .btn-outline-orange.dropdown-toggle {
  background-color: #fa7205;
  border-color: #fa7205;
  color: #fff;
}
.btn-outline-orange:hover {
  background-color: #fa7205;
  border-color: #fa7205;
  color: #fff !important;
}
.btn-outline-orange.focus,
.btn-outline-orange:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-orange:focus {
  color: #fa7205;
}
.btn-outline-orange.fill {
  background-color: #fa7205;
  color: #fff !important;
}
/* ./END - orange button */

/* orange button */
.btn-info {
  background-color: #00c0ef;
  background-image: none;
  border-color: #00c0ef;
  color: #fff;
}
.btn-info:hover {
  background-color: #05a5ef;
  border-color: #05a5ef;
  color: #fff;
}

.btn-outline-info {
  background-color: transparent;
  background-image: none;
  border-color: #05a5ef;
  color: #05a5ef;
}
.btn-outline-info:hover {
  background-color: #00c0ef;
  border-color: #00c0ef;
  color: #fff !important;
}
.btn-outline-info:focus {
  color: #05a5ef;
}
/* ./END - info button */

/* red button */
.btn-outline-red,
.btn-outline-danger {
  background-color: transparent;
  background-image: none;
  border-color: #f74a4a;
  color: #f74a4a;
}
.btn-outline-red:hover,
.btn-outline-danger:hover {
  background-color: #f74a4a;
  border-color: #f74a4a;
  color: #fff !important;
}
.btn-outline-red:focus,
.btn-outline-danger:focus {
  color: #f74a4a;
}
/* ./END - red button */

/* default button */
.btn-outline-default {
  background-color: transparent;
  background-image: none;
  border-color: #757575;
  color: #757575;
}
.btn-outline-default:hover {
  background-color: #444;
  border-color: #444;
  color: #fff !important;
}
.btn-outline-default:focus {
  color: #444;
}
/* ./END - default button */

.bg-orange {
  background-color: #ff851b !important;
}
.bg-red {
  background-color: #dd4b39 !important;
}
.bg-green {
  background-color: #00a65a !important;
}

.btn-small {
  padding: 6px;
  line-height: 12px;
  font-size: 14px;
}

.table th {
  border-top: none;
}
.has-stock-alert {
  background: red;
  color: #fff;
  text-align: center;
}
.orange-expiry-alert {
  background: orange;
  color: #fff;
  text-align: center;
}
.react-month-picker .calendar-container {
  display: none;
}
.react-month-picker input {
  width: 98px !important;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.manage-privacy-block {
  list-style: none;
  margin: 0;
  padding: 0;
}
.manage-privacy-block li {
  border-bottom: 1px solid #e2e0e0;
  padding: 8px;
}
.manage-privacy-block li a,
.manage-privacy-block a:hover {
  text-decoration: none;
}
.modal-max-enlarge-width .modal-dialog {
  max-width: 90%;
}
.fixed-button-row {
  position: fixed;
  bottom: 0;
  background: #d0d1d6;
  width: 100%;
  padding: 10px;
}

/* orange panel */
.chart-panel.orange {
  background: orange;
}
.chart-panel.orange .left {
  background: #da8d00;
  color: #442d03;
}
.chart-panel.orange .sp {
  border-bottom: 3px solid #ea9700;
}

/* green panel */
.chart-panel.green {
  background: #02b943;
}
.chart-panel.green .left {
  background: #039a38;
  color: #013e16;
}
.chart-panel.green .sp {
  border-bottom: 3px solid #11a245;
}

/* red panel */
.chart-panel.red {
  background: #fd441a;
}
.chart-panel.red .left {
  background: #dc3a16;
  color: #691907;
}
.chart-panel.red .sp {
  border-bottom: 3px solid #e82d03;
}

/* blue panel */
.chart-panel.blue {
  background: #27a9f5;
}
.chart-panel.blue .left {
  background: #1890d6;
  color: #043d5f;
}
.chart-panel.blue .sp {
  border-bottom: 3px solid #1390da;
}

.chart-panel .left {
  font-size: 26px;
  font-weight: 600;
}

.chart-panel .top {
  color: #fff;
  font-size: 15px;
  padding: 2px 10px;
  text-transform: uppercase;
}

.chart-panel .bot {
  color: #fff;
  font-size: 13px;
  padding: 5px 10px;
}

.login-container {
  margin: 0 auto;
  border: 1px solid #e8e8f7;
  width: 400px;
  margin-top: 150px;
  background: #f0f0f7;
}
.badge.bg-orange {
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
.badge.bg-red {
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}
.badge.bg-default {
  background-color: #777;
  border-radius: 12px;
  color: #fff;
  font-size: 14px;
}

table.no-border td {
  border: none;
}
.preview-bill-block {
  background: #e7ffd5;
  color: #1f3510;
}
.preview-bill-block td,
.preview-bill-block th {
  border-color: #c7e8af !important;
  color: #1f3510;
}

.error-message {
  list-style: none;
  padding: 10px;
  border: 1px dotted #3a0e0e;
  background: #c32626;
  color: #fffcfc;
}
.nav-clock time {
  color: #fff;
  padding-left: 40px;
}
.nav-clock .system-calendar-clock-block {
  position: absolute;
  right: 0;
  margin-right: 0px;
  background: #2f2c2c;
  width: 177px;
  text-align: left !important;
  padding-left: 5px;
  margin-top: -8px;
}
.nav-clock div {
  text-align: left !important;
  padding-top: 3px;
}
.nav-clock span {
  color: #fff;
  font-weight: 600;
  font-size: 26px;
  padding: 0;
}
.renewal-key-block {
  background: #f2f5f9;
  border: 1px solid #e7ecf3;
  padding: 10px;
}
.account-name-header-block {
  position: absolute;
  width: 420px;
  text-align: center;
  left: 50%;
  margin-left: -164px;
  margin-top: -10px;
}
.account-expiration-alert-header-block {
  font-size: 13px;
  background: red;
  padding: 2px 7px;
  color: #fff;
  position: absolute;
  width: 420px;
  text-align: center;
  left: 50%;
  margin-left: -155px;
  margin-top: 24px;
}
.list-group-link-block .list-group-item-action {
  padding: 0;
}
.list-group-link-block .list-group-item-action a {
  padding: 10px;
  padding-left: 20px;
  text-decoration: none;
}
.list-group-link-block a.active a {
  color: #fff;
}

/* margins */
.mr-10 {
  margin-right: 10px;
}
/* /. margins */

/* font */
.font-18 {
  font-size: 18px;
}
.font-12 {
  font-size: 12px;
}
.font-11 {
  font-size: 11px;
}
.font-10 {
  font-size: 10px;
}
.font-9 {
  font-size: 9px;
}
/* /. font */

.lab-report-tree-block .level-1 {
  margin-left: 30px;
}

.lab-report-tree-block .level-2 {
  margin-left: 60px;
}

.lab-report-tree-block .level-3 {
  margin-left: 90px;
}
.required {
  color: red;
}
.rug .rug-handle:before {
  border: 3px dashed #d1d6da;
}
.rug {
  font-size: 16px;
  color: #aab1b7;
}
.rug-list .rug-list-upload-button {
  display: none;
}
.rug-list .rug-list-progress-count {
  display: none;
}
.bg-blue {
  background: #3e555a !important;
}
.bg-blue a {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.bg-blue .dropdown-item.active,
.bg-blue .dropdown-item.focus,
.bg-blue .dropdown-item:active {
  color: #fff;
  background-color: #27a6d6;
}

.bg-blue a.dropdown-item {
  color: #0982a5;
}

.main-menu-nav-pill {
  margin-right: 15px;
  text-transform: uppercase;
}
.navbar-nav .dropdown-menu {
  margin-top: 4px !important;
}

.slick-slide img {
  width: 300px;
  height: 300px;
}

.card-img-top {
  height: 300px;
}
.header-quick-link a {
  color: #3e4665;
}

/* sort */

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}
.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}
.Showcase__style__handle {
  position: relative;
  top: 1px;
  display: block;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
}
.SortableItem.active {
  background: #daf794;
}
/* sort */

.hide-sort-tree-move .rst__moveHandle {
  display: none;
}

.hide-sort-tree-move .rst__rowContents {
  border-left: 1px solid #ccc;
}

.hide-sort-tree-move .rstcustom__collapseButton {
  display: none;
}

/* ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
} */

.home-section-product-list-md-2 .price-block {
  font-size: 13px;
  font-weight: 600;
}

.home-section-product-list-md-2 .product-cart-left {
  right: 30px;
  width: 25px;
}

.home-section-product-list-md-2 .product-wish-right {
  width: 25px;
}
.header-block {
  padding: 25px;
}

.tbl-list-view th,
.tbl-list-view td {
  padding: 8px !important;
}

.col-12 .product-cart-left {
  position: inherit;
}
.image-border-8 {
  border: 8px solid #fff;
}
.italic {
  font-style: italic;
}
.text-right {
  text-align: right;
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  /* text-transform: uppercase; */
  font-size: 16px;
  margin-right: 20px;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #e8e8e8;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: #e8e8e8;
}
.navbar-light .navbar-toggler {
  color: rgb(238 236 236 / 50%);
  border-color: rgb(255 255 255 / 10%);
  background: #fff;
}
.nav-right {
  position: absolute;
  right: 50px;
}
.nav-right div{
  font-size: 12px;
  font-style: italic;
  color: #ccc;
}
.nav-right .label{
  margin-right: 8px;
  float: left;
}


.no-margin {
  margin: 0;
}
.bg-dark {
  background-color: #000 !important;
}
.logo-image {
  width: 250px;
}
.carousel-image {
  width: 100%; /* Adjust width as needed */
  height: 600px; /* Set a fixed height */
  /* object-fit: cover;  */
}
.carousel-caption h5 {
  background: #000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  margin: 0;
}
.carousel-caption p {
  background: #000;
  padding: 8px;
}
.footer .card {
  border-radius: 0;
  border: none;
  border-top: 1px solid;
  color: #e1dede;
  background: #181818;
  padding: 0 30px;
}
.footer .card-text {
  font-size: 13px;
}
.footer .card-footer {
  background: #000;
}
.footer-last {
  background: #000;
  color: #8a8585;
  text-align: center;
  padding: 5px;
  font-size: 12px;
}

@media (max-width: 767px) {
  .nav-right {
    right: 0px;
    bottom: 10px;
  }
  .carousel-image {
    height: 300px !important; /* Set a fixed height */
    object-fit: cover !important; 
  }
  .carousel-image.sm {
    height: 130px !important; /* Set a fixed height */
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nav-right {
    right: 0px;
    bottom: 10px;
  }
  .carousel-image {
    height: 300px !important; /* Set a fixed height */
    object-fit: cover !important; 
  }
  .carousel-image.sm {
    height: 130px !important; /* Set a fixed height */
  }
  
}